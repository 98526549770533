import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 5; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/5-sep-1/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }
  return imgArray;
})();

export default function PuppetShow() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Puppet show</h1>

        <p>
          <strong>
            ”When we teach through puppets we give children something to
            remember the story with.”
          </strong>
          <br />
          <br />
          keeping this in mind the Pre - Primary Department of Chinmaya
          Vidyalaya, New Delhi organised a ‘Puppet show’ on 22nd august 2023.
          The facilitator depicted the story ‘Will you be my friend?’ through
          animal puppets. It aimed at enhancing the learner’s communication and
          social skills by providing structured opportunities to interact,
          imagine, and most importantly convey a moral message that “Friends
          come in all shapes and sizes!. The young learners enjoyed
          the show eminently.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
